import React from 'react';
import { compose } from 'recompose';

import Layout from '../components/layout';
import {
  AuthUserContext,
  withAuthorization,
  withEmailVerification,
} from '../components/Session';
// import Messages from '../components/Messages';
import SectionWrapper from '../components/SectionWrapper/SectionWrapper';
import AuthReviews from '../components/AuthReviews';
import * as ROLES from '../constants/roles'

const HomePageBase = () => (
  <AuthUserContext.Consumer>
    {authUser => (
      <div>
        <h1>{authUser.roles && authUser.roles[ROLES.ADMIN] ? "All Reviews" : "Your Review"}</h1>

        {/* <Messages /> */}
        <AuthReviews />
      </div>
    )}
  </AuthUserContext.Consumer>

);

const condition = authUser => !!authUser;

const HomePage = compose(
  withEmailVerification,
  withAuthorization(condition),
)(HomePageBase);

export default () => (
  <Layout>
    <SectionWrapper sectionId="homepage">
      <HomePage />
    </SectionWrapper>
  </Layout>
);
