import React from 'react'

const Footer = () => (
  <>
    <footer
      className="page_footer ds section_padding_top_100 section_padding_bottom_100 columns_padding_25 table_section table_section_md">

    </footer>
    <section className="ls page_copyright section_padding_15">
      <div className="container">
        <div className="row">
          <div className="col-sm-12 text-center">
            <p className="small-text">&copy; Copyright 2019 All Rights Reserved. ROC license #317662 <a href="/privacy">Privacy Policy</a></p>
            
          </div>
        </div>
      </div>
    </section>
  </>
)
export default Footer