import React from 'react'

const TopBar = ({children}) => (
    <section className="page_topline ds table_section section_padding_top_5 section_padding_bottom_5">
        <div className="container-fluid">
            <div className="row">
                <div className="col-sm-6 text-center text-sm-left">
                    <p>
                        <i className="fa fa-clock-o grey rightpadding_5" aria-hidden="true"></i> Opening Hours: Weekdays 8am-6pm
                    </p>
                </div>
                <div className="col-sm-6 text-center text-sm-right">
                    <div className="inline-content">
                        <p>
                            <i className="fa fa-phone grey rightpadding_5" aria-hidden="true"></i> (520) 273-5626
                        </p>
                        <p className="greylinks">
                            <i className="fa fa-envelope-o grey rightpadding_5" aria-hidden="true"></i> <a
                                href="mailto:ecoronado66@gmail.com">ecoronado66@gmail.com</a>
                        </p>
                        {children}
                    </div>
                </div>
            </div>
        </div>
    </section>
)

export default TopBar