import React from 'react';

import AuthReviewItem from './AuthReviewItem';

const AuthReviewList = ({
  authUser,
  reviews,
  onEditReview,
  onRemoveReview,
}) => (
    <>

      {reviews.map(review => (

        <AuthReviewItem
          authUser={authUser}
          key={review.createdAt}
          review={review}
          onEditReview={onEditReview}
          onRemoveReview={onRemoveReview}
        />

      ))}
    </>
  );

export default AuthReviewList;
