import React, { Component, Fragment } from 'react';
import {Helmet} from 'react-helmet'
import Navigation from './Navigation';
import getFirebase, { FirebaseContext } from './Firebase';
import withAuthentication from './Session/withAuthentication';
import Footer from './Footer/Footer';

class Layout extends Component {
  state = {
    firebase: null,
  };

  componentDidMount() {
    const app = import('firebase/app');
    const auth = import('firebase/auth');
    const database = import('firebase/database');

    Promise.all([app, auth, database]).then(values => {
      const firebase = getFirebase(values[0]);

      this.setState({ firebase });
    });
  }

  render() {
    return (
      <FirebaseContext.Provider value={this.state.firebase}>
        <AppWithAuthentication {...this.props} />
      </FirebaseContext.Provider>
    );
  }
}

const AppWithAuthentication = withAuthentication(({ children }) => (
  <Fragment>
    <Helmet>
      <title>Coronado Roofing</title>
      <meta charset="utf-8" />

      <meta name="description" content="" />
      <meta name="viewport" content="width=device-width, initial-scale=1" />

      <link rel="stylesheet" href="/css/bootstrap.min.css" />
      <link rel="stylesheet" href="/css/animations.css" />
      <link rel="stylesheet" href="/css/fonts.css" />
      <link rel="stylesheet" href="/css/main.css" class="color-switcher-link" />      
    </Helmet>
    <div id="canvas">
      <a name="box_wrapper"></a>
      <div id="box_wrapper">
        <Navigation />
        {children}
        <Footer />
      </div>
    </div>
  </Fragment>
));

export default Layout;
