import React, { Component } from 'react';
import * as ROLES from '../../constants/roles';

class AuthReviewItem extends Component {
  constructor(props) {
    super(props);

    this.state = {
      editMode: false,
      editText: this.props.review.text,
      editStars: this.props.review.stars,
    };
  }

  onToggleEditMode = () => {
    this.setState(state => ({
      editMode: !state.editMode,
      editText: this.props.review.text,
      editStars: this.props.review.stars,
    }));
  };

  onChangeEditText = event => {
    this.setState({ editText: event.target.value });
  };

  onChangeEditStars = event => {
    this.setState({ editStars: event.target.getAttribute("starvalue") });
  };

  onSaveEditText = () => {
    this.props.onEditReview(this.props.review, this.state.editText, this.state.editStars, false);

    this.setState({ editMode: false });
  };

  onPublishReview = () => {
    this.props.onEditReview(this.props.review, this.props.review.text, this.props.review.stars, true)
  }

  onArchiveReview = () => {
    this.props.onEditReview(this.props.review, this.props.review.text, this.props.review.stars, false)
  }

  render() {
    const { authUser, review, onRemoveReview } = this.props;
    const { editMode, editText, editStars } = this.state;
    // console.log(review);
    return (
      <blockquote className="with_border with_padding big-padding text-center margin_0">
        {editMode ? (
          <>
            <img src={review.userPhoto} alt="" />
            
            <textarea
              className="form-control"
              placeholder="Enter your review"
              value={editText}
              onChange={this.onChangeEditText}>
            </textarea>
            <hr className="divider_60_2 main_bg_color divider_25" />
            <div className="item-meta topmargin_0">
              <h5>{review.username}</h5>
              <div className="starsContainer">
                <span starvalue="1" onClick={this.onChangeEditStars} className={editStars >= 1 ? 'fa fa-star checked' : 'fa fa-star'}></span>
                <span starvalue="2" onClick={this.onChangeEditStars} className={editStars >= 2 ? 'fa fa-star checked' : 'fa fa-star'}></span>
                <span starvalue="3" onClick={this.onChangeEditStars} className={editStars >= 3 ? 'fa fa-star checked' : 'fa fa-star'}></span>
                <span starvalue="4" onClick={this.onChangeEditStars} className={editStars >= 4 ? 'fa fa-star checked' : 'fa fa-star'}></span>
                <span starvalue="5" onClick={this.onChangeEditStars} className={editStars >= 5 ? 'fa fa-star checked' : 'fa fa-star'}></span>
              </div>
            </div>
          </>
        ) : (
            <>
              <img src={review.userPhoto} alt="" />
              {review.text}
              <hr className="divider_60_2 main_bg_color divider_25" />
              <div className="item-meta topmargin_0">
                <h5>{review.username}</h5>
                <div className="starsContainer">
                  <span className={review.stars >= 1 ? 'fa fa-star checked' : 'fa fa-star'}></span>
                  <span className={review.stars >= 2 ? 'fa fa-star checked' : 'fa fa-star'}></span>
                  <span className={review.stars >= 3 ? 'fa fa-star checked' : 'fa fa-star'}></span>
                  <span className={review.stars >= 4 ? 'fa fa-star checked' : 'fa fa-star'}></span>
                  <span className={review.stars >= 5 ? 'fa fa-star checked' : 'fa fa-star'}></span>
                </div>
                {/* <p className="small-text">{review.description}</p> */}
                {/* {review.editedAt && <span>(Edited)</span>} */}
              </div>
            </>
          )}

        {authUser && authUser.uid === review.userId && (
          <span>
            {editMode ? (
              <span>
                <button onClick={this.onSaveEditText}>Save</button>
                <button onClick={this.onToggleEditMode}>Reset</button>
              </span>
            ) : (
                <button onClick={this.onToggleEditMode}>Edit</button>
              )}

            {!editMode && (
              <button
                type="button"
                onClick={() => onRemoveReview(review.uid)}
              >
                Delete
              </button>
            )}
          </span>
        )}
        {authUser && authUser.roles && authUser.roles[ROLES.ADMIN] && (
          <span>
            
            {!review.published && <button onClick={this.onPublishReview}>Publish</button>}
            {review.published && <button onClick={this.onArchiveReview}>Archive</button>}

            <button type="button" onClick={() => onRemoveReview(review.uid)}>
              Delete
            </button>
          </span>
        )}
      </blockquote>
    );
  }
}

export default AuthReviewItem;
