import React, { Component } from 'react';

import { AuthUserContext } from '../Session';
import { withFirebase } from '../Firebase';
import AuthReviewList from './AuthReviewList';
import * as ROLES from '../../constants/roles';

class AuthReviews extends Component {
  _initFirebase = false;

  constructor(props) {
    super(props);

    this.state = {
      text: '',
      stars: 5,
      published: false,
      loading: false,
      reviews: [],
      limit: 5,
    };
  }

  firebaseInit = () => {
    if (this.props.firebase && !this._initFirebase) {
      this._initFirebase = true;

      this.onListenForReviews();
    }
  };

  componentDidMount() {
    this.firebaseInit();  
  }

  componentDidUpdate() {
    this.firebaseInit();
  }

  onListenForReviews = () => {
    this.setState({ loading: true });
    let authUser = JSON.parse(localStorage.getItem('authUser'));
    let userId = authUser.uid;
    if (authUser && authUser.roles && authUser.roles[ROLES.ADMIN]) {
      this.props.firebase
        .reviews()
        .orderByChild('createdAt')
        .limitToLast(this.state.limit)
        .on('value', snapshot => {
          const reviewObject = snapshot.val();

          if (reviewObject) {
            const reviewList = Object.keys(reviewObject).map(key => ({
              ...reviewObject[key],
              uid: key,
            }));

            this.setState({
              reviews: reviewList,
              loading: false,
            });
          } else {
            this.setState({ reviews: null, loading: false });
          }
        });
    } else if (userId){
      //non auth users only retrieve their own review

      this.props.firebase
        .review(userId)
        .on('value', snapshot => {
          const reviewObject = snapshot.val();

          if (reviewObject) {
            reviewObject.uid = reviewObject.userId;

            this.setState({
              reviews: [reviewObject],
              loading: false,
            });
          } else {
            this.setState({ reviews: null, loading: false });
          }
        });
    }

  };

  componentWillUnmount() {
    this.props.firebase.reviews().off();
  }

  onChangeText = event => {
    this.setState({ text: event.target.value });
  };

  onChangeStars = (event) => {
    this.setState({ stars: event.target.getAttribute("starvalue") });
  };

  onCreateReview = (event, authUser) => {
    let userPhoto = authUser.photoURL || "/images/faces/blank.jpg"
    this.props.firebase.reviews().child(authUser.uid).set({
      text: this.state.text,
      stars: this.state.stars,
      published: false,
      userId: authUser.uid,
      username: authUser.username,
      userPhoto: userPhoto,
      createdAt: this.props.firebase.serverValue.TIMESTAMP,
    });

    this.setState({ text: '' });

    event.preventDefault();
  };

  onEditReview = (review, text, stars, published) => {
    const { uid, ...reviewSnapshot } = review;

    this.props.firebase.review(review.uid).set({
      ...reviewSnapshot,
      text,
      stars,
      published,
      editedAt: this.props.firebase.serverValue.TIMESTAMP,
    });
  };

  onRemoveReview = uid => {
    this.props.firebase.review(uid).remove();
  };

  onNextPage = () => {
    this.setState(
      state => ({ limit: state.limit + 5 }),
      this.onListenForReviews,
    );
  };

  render() {
    const { text, stars, reviews, loading } = this.state;
    
    return (
      <AuthUserContext.Consumer>
        {authUser => (
          <div>
            

            {loading && <div>Loading ...</div>}
            {!reviews && authUser.roles && authUser.roles[ROLES.ADMIN] && <div>There are no reviews.</div>}
            {reviews && (
              <AuthReviewList
                authUser={authUser}
                reviews={reviews}
                onEditReview={this.onEditReview}
                onRemoveReview={this.onRemoveReview}
              />
            )}

           
            {!loading && reviews && reviews.length > 5 && authUser && authUser.roles && authUser.roles[ROLES.ADMIN] && (
              <button type="button" onClick={this.onNextPage}>
                More
              </button>
            )}
            {!reviews && authUser && !authUser.roles[ROLES.ADMIN] && (
              <blockquote className="with_border with_padding big-padding text-center margin_0">
                <img src={authUser.photoURL} alt="" />
                  <form onSubmit={event => this.onCreateReview(event, authUser)}>
                  <textarea
                    className="form-control"
                    placeholder="Enter your review"
                    value={text}
                    onChange={this.onChangeText}
                  ></textarea>
                  
                  <hr className="divider_60_2 main_bg_color divider_25" />
                  <div className="item-meta topmargin_0">
                    <h5>{authUser.username}</h5>
                    <div className="starsContainer">
                      <span starvalue="1" onClick={this.onChangeStars} className={stars >= 1 ? 'fa fa-star checked' : 'fa fa-star'}></span>
                      <span starvalue="2" onClick={this.onChangeStars} className={stars >= 2 ? 'fa fa-star checked' : 'fa fa-star'}></span>
                      <span starvalue="3" onClick={this.onChangeStars} className={stars >= 3 ? 'fa fa-star checked' : 'fa fa-star'}></span>
                      <span starvalue="4" onClick={this.onChangeStars} className={stars >= 4 ? 'fa fa-star checked' : 'fa fa-star'}></span>
                      <span starvalue="5" onClick={this.onChangeStars} className={stars >= 5 ? 'fa fa-star checked' : 'fa fa-star'}></span>
                    </div>
                  </div>
                  <button type="submit">Send</button>
                </form>
              </blockquote>
            )}
          </div>
        )}
      </AuthUserContext.Consumer>
    );
  }
}

export default withFirebase(AuthReviews);
