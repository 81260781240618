import React from 'react';
import { Link } from 'gatsby';

import { AuthUserContext } from '../Session';
import SignOutButton from '../SignOut';
import * as ROUTES from '../../constants/routes';
import * as ROLES from '../../constants/roles';
import TopBar from '../TopBar/TopBar';

const Navigation = () => (
  <AuthUserContext.Consumer>
    {authUser =>
      authUser ? (
        <>
          <TopBar>
            <NavigationAuth authUser={authUser} />
          </TopBar>
          <NavigationNonAuth />
        </>
      ) : (
          <>
            <TopBar />
            <NavigationNonAuth />
          </>
        )
    }
  </AuthUserContext.Consumer>
);

const NavigationAuth = ({ authUser }) => (
  <>
    <p className="greylinks">

      <Link to={ROUTES.HOME}>Home</Link>
      &nbsp;|&nbsp;
      <Link to={ROUTES.ACCOUNT}>Account</Link>
      &nbsp;|&nbsp;
      {!!authUser.roles[ROLES.ADMIN] && (
        <Link to={ROUTES.ADMIN}>Admin</Link>
      )}
      
    </p>
    <p className="greylinks">
      <SignOutButton />
    </p>
  </>
);

const NavigationNonAuth = () => (
  <header className="page_header header_white toggler_xs_right section_padding_20">
    <div className="container-fluid">
      <div className="row">
        <div className="col-sm-12 display_table">
          <div className="header_left_logo display_table_cell">
            <a href="/" className="logo top_logo text-left"> <img src="/images/coronado-logo.jpeg" alt="" />
              Coronado Roofing
            </a>
          </div>

          <div className="header_mainmenu display_table_cell text-center">
            <nav className="mainmenu_wrapper">
              <ul className="mainmenu nav sf-menu">
                <li className="active"><Link to="/#box_wrapper">Home</Link></li>
                <li><Link to="/#services">Services</Link></li>
                <li><Link to="/#reviews">Reviews</Link></li>
                <li><Link to="/#gallery">Gallery</Link></li>
                <li><a href="mailto:ecoronado66@gmail.com">Contact Us</a></li>
              </ul>
            </nav>
            <span className="toggle_menu"> <span></span>
            </span>
          </div>

          <div className="header_right_buttons display_table_cell text-right hidden-xs">
            <div className="darklinks">
              <a href="#" className="social-icon color-icon border-icon soc-twitter">&nbsp;</a>
              <a href="#" className="social-icon color-icon border-icon soc-facebook">&nbsp;</a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </header>
);

export default Navigation;
