import React from 'react'

const SectionWrapper = ({children, sectionId}) => (
  <section id={sectionId} className="ls section_padding_100">
    <div className="container">
      <div className="row">
      {children}
      </div>
    </div>
  </section>
)

export default SectionWrapper